export enum FilterMessages {
  infoAppliedText = "filter.info.appliedText",
  infoCorrespondenceText = "filter.info.correspondenceText",
  infoNoFiltersApplied = "filter.info.noFiltersApplied",
  infoNewFieldFilter = "filter.info.newFieldFilter",
  infoRequiredField = "filter.info.requiredField",
  infoMaxFilterFields = "filter.info.maxFilterFields",
  buttonAddFieldsFilter = "filter.button.addFieldsFilter",
  buttonRemoveAllFieldsFilter = "filter.button.removeAllFieldsFilter",
  buttonSaveFieldFilter = "filter.button.saveFieldFilter",
  buttonApplyFilter = "filter.button.applyFilter",
  buttonSaveSelectionsAsNewFilter = "filter.button.saveSelectionsAsNewFilter",
  buttonSaveChangesToSelectedFilter = "filter.button.saveChangesToSelectedFilter",
  buttonSetFilterAsDefault = "filter.button.setFilterAsDefault",
  buttonRemoveFilterDefault = "filter.button.removeFilterDefault",
  labelFieldProperty = "filter.label.fieldProperty",
  labelFieldOperator = "filter.label.fieldOperator",
  labelFieldValue = "filter.label.fieldValue",
  labelFilterOptions = "filter.label.filterOptions",
  labelChooseFilterName = "filter.label.changeFilterName",
  labelFilterName = "filter.label.filterName",
  labelSavedFilters = "filter.label.savedFilters",
  labelFilterDataOnScreen = "filter.label.filterDataOnScreen",
  operatorEqual = "filter.operator.equal",
  operatorNotEqual = "filter.operator.notEqual",
  operatorGreater = "filter.operator.greater",
  operatorGreaterOrEqual = "filter.operator.greaterOrEqual",
  operatorLess = "filter.operator.less",
  operatorLessOrEqual = "filter.operator.lessOrEqual",
  operatorContains = "filter.operator.contains",
  operatorNotContains = "filter.operator.notContains",
  operatorStartsWith = "filter.operator.startsWith"

}

export interface TranslationFilterMessages {
  [FilterMessages.infoAppliedText]: string;
  [FilterMessages.infoCorrespondenceText]: string;
  [FilterMessages.infoNoFiltersApplied]: string;
  [FilterMessages.infoNewFieldFilter]: string;
  [FilterMessages.infoRequiredField]: string;
  [FilterMessages.infoMaxFilterFields]: string;
  [FilterMessages.buttonAddFieldsFilter]: string;
  [FilterMessages.buttonRemoveAllFieldsFilter]: string;
  [FilterMessages.buttonSaveFieldFilter]: string;
  [FilterMessages.buttonApplyFilter]: string;
  [FilterMessages.buttonSaveSelectionsAsNewFilter]: string;
  [FilterMessages.buttonSaveChangesToSelectedFilter]: string;
  [FilterMessages.buttonSetFilterAsDefault]: string;
  [FilterMessages.buttonRemoveFilterDefault]: string;
  [FilterMessages.labelFieldProperty]: string;
  [FilterMessages.labelFieldOperator]: string;
  [FilterMessages.labelFieldValue]: string;
  [FilterMessages.labelFilterOptions]: string;
  [FilterMessages.labelChooseFilterName]: string;
  [FilterMessages.labelFilterName]: string;
  [FilterMessages.labelSavedFilters]: string;
  [FilterMessages.labelFilterDataOnScreen]: string;
  [FilterMessages.operatorEqual]: string;
  [FilterMessages.operatorNotEqual]: string;
  [FilterMessages.operatorGreater]: string;
  [FilterMessages.operatorGreaterOrEqual]: string;
  [FilterMessages.operatorLess]: string;
  [FilterMessages.operatorLessOrEqual]: string;
  [FilterMessages.operatorContains]: string;
  [FilterMessages.operatorNotContains]: string;
  [FilterMessages.operatorStartsWith]: string;
}
