import styled from "styled-components";

import { Dialog, DialogTitle } from "@libraries/mui/components";

import { windowWidth } from "@styles/global";

const Container = styled(Dialog)`

  &.without-padding {
    .MuiDialog-paper {
      padding: 0 !important;
    }

    .MuiDialogTitle-root {
      padding: 20px !important;
      gap: 40px !important;
    }
  }

  .MuiDialog-paper {
    padding: 20px !important;
    border-radius: 10px !important;
    min-width: 358px !important;
    width: 100%;
    max-width: ${(props) => (props.fullWidth ? "initial" : "fit-content")};

    @media ( max-width: ${windowWidth.mobile.large} ) {
      min-width: 95% !important;
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Header = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 !important;
    padding-bottom: 20px !important;
  }
`;

const Title = styled.span`
  line-height: 1.7rem;
  font-size: 1.5rem;
  
  @media ( max-width: ${windowWidth.tablet} ) {
    font-size: 1.3rem;
  }
  
`;

const Subtitle = styled.div`
  font-size: 1rem;
  color: ${(props) => props.theme.colors.gray};
  line-height: 1.2rem;
`;

export { Container, Header, Title, Subtitle };
