import * as Yup from "yup";
import { YupMessages } from "@languages/interfaces/yupMessages";

Yup.setLocale({
  mixed: {
    default: () => ({ key: YupMessages.mixedDefault, values: {} }),
    required: () => ({ key: YupMessages.mixedRequired, values: {} }),
    oneOf: ({ values }) => ({ key: YupMessages.mixedOneOf, values: { values } }),
    notOneOf: ({ values }) => ({ key: YupMessages.mixedNotOneOf, values: { values } }),
    notType: ({ type }) => ({ key: YupMessages.mixedNotType, values: { type } })
  },
  string: {
    length: ({ length }) => ({ key: YupMessages.stringLength, values: { length } }),
    min: ({ min }) => ({ key: YupMessages.stringMin, values: { min } }),
    max: ({ max }) => ({ key: YupMessages.stringMax, values: { max } }),
    matches: ({ regex }) => ({ key: YupMessages.stringMatches, values: { regex } }),
    email: () => ({ key: YupMessages.stringEmail, values: {} }),
    url: () => ({ key: YupMessages.stringUrl, values: {} }),
    trim: () => ({ key: YupMessages.stringTrim, values: {} }),
    lowercase: () => ({ key: YupMessages.stringLowerCase, values: {} }),
    uppercase: () => ({ key: YupMessages.stringUpperCase, values: {} })
  },
  number: {
    min: ({ min }) => ({ key: YupMessages.numberMin, values: { min } }),
    max: ({ max }) => ({ key: YupMessages.numberMax, values: { max } }),
    lessThan: ({ less }) => ({ key: YupMessages.numberLessThan, values: { less } }),
    moreThan: ({ more }) => ({ key: YupMessages.numberMoreThan, values: { more } }),
    positive: () => ({ key: YupMessages.numberPositive, values: {} }),
    negative: () => ({ key: YupMessages.numberNegative, values: {} }),
    integer: () => ({ key: YupMessages.numberInteger, values: {} })
  },
  date: {
    min: ({ min }) => ({ key: YupMessages.dateMin, values: { min } }),
    max: ({ max }) => ({ key: YupMessages.dateMax, values: { max } })
  },
  object: {
    noUnknown: ({ path }) => ({ key: YupMessages.objectNoUnknown, values: { path } })
  },
  array: {
    min: ({ min }) => ({ key: YupMessages.arrayMin, values: { min } }),
    max: ({ max }) => ({ key: YupMessages.arrayMax, values: { max } })
  }
});

export { ValidationError } from "yup";
export default Yup;
