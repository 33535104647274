import { ResetPasswordMessages, TranslationResetPasswordMessages } from "../interfaces/resetPasswordMessages";

// eslint-disable-next-line import/prefer-default-export
export const messages: TranslationResetPasswordMessages = {
  [ResetPasswordMessages.resetPasswordLabel]: "Senha",
  [ResetPasswordMessages.resetPasswordRequired]: "A senha é obrigatória",
  [ResetPasswordMessages.resetPasswordButton]: "Confirma nova senha",
  [ResetPasswordMessages.resetPasswordGoToLoginPageButton]: "Ir para a página de login",
  [ResetPasswordMessages.resetPasswordFailed]: "Falha ao confirmar nova senha",
  [ResetPasswordMessages.resetPasswordConfirmAccountInfo]: "Por favor, confirme sua conta para restaurar sua senha",
  [ResetPasswordMessages.resetPasswordConfirmAccountMessage]: "Conta confirmada com sucesso!<br><br>"
  + "Clique no botão abaixo para realizar seu primeiro acesso!<br>"
  + "Bem-vindo ao Topcon Suite",
  [ResetPasswordMessages.resetPasswordForgotPasswordInfo]: "Por favor, insira sua nova senha",
  [ResetPasswordMessages.resetPasswordForgotPasswordMessage]: "Senha restaurada com sucesso!<br><br>"
  + "Clique no botão abaixo para realizar acesso!"
};
