import React from "react";

import * as _ from "lodash";

import { Navbar } from "src/molecules";

import * as Styled from "@pages/Office/styles";

const Office: React.FC = ({ children }) => {
  const user = localStorage.getItem("@Topcon:user");
  const accessGranted = !_.isEmpty(user);

  return (
    <Styled.Container>
      <Styled.NavbarContainer>
        {accessGranted && <Navbar />}
      </Styled.NavbarContainer>
      <Styled.ContentContainer>
        <Styled.Content id="officeContent">
          {children}
        </Styled.Content>
      </Styled.ContentContainer>
    </Styled.Container>
  );
};

export default Office;
