import { LoginTheme } from ".";

const theme: LoginTheme = {
  title: "dark",
  colors: {
    primary: "rgb(59,61,80)",
    secondary: "rgb(0,86,154)",
    tertiary: "rgb(0, 41, 81)",
    background: "rgb(26, 30, 48)",
    scrollBar: "#e0e0e0",
    text: "white",
    navBarIcons: "white",
    gray: "#545454",
    error: "#e74c3c",
    success: "#46c17d"
  }
};

export default theme;
