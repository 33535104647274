export enum UserFormMessages {
  fieldFullName = "userForm.fields.fieldFullName",
  fieldEmail = "userForm.fields.fieldEmail",
  fieldAdmin = "userForm.fields.fieldAdmin",
  fieldNotAdmin = "userForm.fields.fieldNotAdmin",
  fieldActive = "userForm.fields.fieldActive",
  fieldInactive = "userForm.fields.fieldInactive",
  fieldPassword = "userForm.fields.fieldPassword",
  buttonSave = "userForm.buttons.buttonSave"
}

export interface TranslationUserFormMessages {
  [UserFormMessages.fieldFullName]: string;
  [UserFormMessages.fieldEmail]: string;
  [UserFormMessages.fieldAdmin]: string;
  [UserFormMessages.fieldNotAdmin]: string;
  [UserFormMessages.fieldActive]: string;
  [UserFormMessages.fieldInactive]: string;
  [UserFormMessages.fieldPassword]: string;
  [UserFormMessages.buttonSave]: string;
}