import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { TelluriaProvider } from "@npm-telluria-tecnologia/telluria-ui/dist";
import AppProvider from "@hooks/index";

import i18nInit from "@languages/i18n";

import useTheme from "@styles/useTheme";
import GlobalStyle from "@styles/global";
import Routes from "./routes/Routes";

const Root: React.FC = () => {

  i18nInit();

  const { theme } = useTheme();

  return (
    <TelluriaProvider components={{ toast: true }}>
      <ThemeProvider theme={theme}>
        <AppProvider>
          <BrowserRouter>
            <GlobalStyle />
            <Routes />
          </BrowserRouter>
        </AppProvider>
      </ThemeProvider>
    </TelluriaProvider>
  );
};

export default Root;
