import React from "react";

import * as Styled from "./styles";

interface LoadingProps {
  logoSvg: string;
  name: string;
  color: string;
}

const Loading: React.FC<LoadingProps> = ({ logoSvg, name, color }) => (
  <Styled.Container>
    <Styled.ModuleIcon src={logoSvg} alt={name} />
    <Styled.ModuleName moduleColor={color}>
      <span>Topcon</span>
      <span className="module">{name}</span>
    </Styled.ModuleName>
  </Styled.Container>
);

export default Loading;
