import styled from "styled-components";

import { windowWidth } from "@styles/global";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 17px;

  @media ( max-width: ${windowWidth.laptop.large} ) {
    > .MuiSvgIcon-root {
      width: 22px !important;
      height: 22px !important;
    }
  }

  @media ( min-width: ${windowWidth.tablet} ) {
    * {
      cursor: pointer;
    }

    &> :not(:hover) {
      transition: all 0.2s;
    }
    
    &> :hover {
      transition: all 0.5s;
      transform: scale(1.2);
    }
  }
`;

const LanguageIcon = styled.div`
  display: flex;
  align-items: center;

  @media ( max-width: ${windowWidth.laptop.large} ) {
    > .MuiSvgIcon-root {
      width: 22px !important;
      height: 22px !important;
    }
  }
`;
const SettingsIcon = styled(LanguageIcon)``;
const ModulesIcon = styled(LanguageIcon)``;

export { Container, LanguageIcon, SettingsIcon, ModulesIcon };