import { UserFormMessages, TranslationUserFormMessages } from "@languages/interfaces/userFormMessages";

export const messages: TranslationUserFormMessages = {
  [UserFormMessages.fieldFullName]: "Nome completo",
  [UserFormMessages.fieldEmail]: "E-mail",
  [UserFormMessages.fieldPassword]: "Senha",
  [UserFormMessages.fieldAdmin]: "Este usuário é administrador",
  [UserFormMessages.fieldNotAdmin]: "Este usuário não é administrador",
  [UserFormMessages.fieldActive]: "Este usuário está ativo",
  [UserFormMessages.fieldInactive]: "Este usuário está inativo",
  [UserFormMessages.buttonSave]: "Salvar"
};
