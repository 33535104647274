import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  .text-center {
    text-align: center;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;

  div {
    padding-left: 5px;
  }
`;
