import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  display: flex;
  
  .loading-icon-area {
    display: block;
    margin: auto;
    z-index: 9999;
  }
`;
