import { UserFormMessages, TranslationUserFormMessages } from "@languages/interfaces/userFormMessages";

export const messages: TranslationUserFormMessages = {
  [UserFormMessages.fieldFullName]: "Nombre completo",
  [UserFormMessages.fieldEmail]: "Email",
  [UserFormMessages.fieldPassword]: "Contraseña",
  [UserFormMessages.fieldAdmin]: "Este usuario es administrador",
  [UserFormMessages.fieldNotAdmin]: "Este usuario no es administrador",
  [UserFormMessages.fieldActive]: "Este usuario está activo",
  [UserFormMessages.fieldInactive]: "Este usuario está inactivo",
  [UserFormMessages.buttonSave]: "Guardar"
};
