import React, { useCallback } from "react";

// region Molecules
import LanguageMenu from "@molecules/LanguageMenu";
import ModuleMenu from "@molecules/ModuleMenu";
// endregion Molecules
// region Libraries
import { Language, Logout, Notifications, Settings, ViewModule } from "@libraries/mui/icons";
// endregion Libraries
// region Languages
import { TLanguages } from "@languages/languageData";
// endregion Languages
// region Styled components
import * as Styled from "src/molecules/NavbarIcons/styles";
// endregion Styled components

const NavbarIcons: React.FC = () => {

  // region States
  const [languageElementAnchor, setLanguageElementAnchor] = React.useState<null | HTMLElement>(null);
  const [moduleElementAnchor, setModuleElementAnchor] = React.useState<null | HTMLElement>(null);
  // endregion States

  // region Handlers
  const handleLogoutClick = useCallback(() => {

    localStorage.removeItem("@Topcon:user");
    localStorage.removeItem("@Topcon:token");

    window.location.href = "/";

  }, []);

  const handleLanguageClick = (event: React.MouseEvent<SVGSVGElement>) => {
    setLanguageElementAnchor(event.currentTarget.parentElement);
  };
  const handleLanguageClose = (_language: TLanguages) => setLanguageElementAnchor(null);

  const handleModuleClick = (event: React.MouseEvent<SVGSVGElement>) => {
    setModuleElementAnchor(event.currentTarget.parentElement);
  };
  const handleModuleClose = () => setModuleElementAnchor(null);
  // endregion Handlers

  return (
    <Styled.Container>
      <Notifications />
      <Styled.SettingsIcon>
        <Settings />
      </Styled.SettingsIcon>
      <Styled.LanguageIcon>
        <Language onClick={handleLanguageClick} />
        <LanguageMenu anchorElement={languageElementAnchor} onClose={handleLanguageClose} />
      </Styled.LanguageIcon>
      <Styled.ModulesIcon>
        <ViewModule onClick={handleModuleClick} />
        <ModuleMenu anchorElement={moduleElementAnchor} onClose={handleModuleClose} />
      </Styled.ModulesIcon>
      <Logout onClick={handleLogoutClick} />
    </Styled.Container>
  );
};

export default React.memo(NavbarIcons);