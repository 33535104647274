import { ResetPasswordMessages, TranslationResetPasswordMessages } from "../interfaces/resetPasswordMessages";

// eslint-disable-next-line import/prefer-default-export
export const messages: TranslationResetPasswordMessages = {
  [ResetPasswordMessages.resetPasswordLabel]: "Password",
  [ResetPasswordMessages.resetPasswordRequired]: "Password is required",
  [ResetPasswordMessages.resetPasswordButton]: "Confirm new password",
  [ResetPasswordMessages.resetPasswordGoToLoginPageButton]: "Go to login page",
  [ResetPasswordMessages.resetPasswordFailed]: "Password reset failed",
  [ResetPasswordMessages.resetPasswordConfirmAccountInfo]: "Please, confirm your account to reset your password",
  [ResetPasswordMessages.resetPasswordConfirmAccountMessage]: "Account confirmed successfully!<br><br>"
  + "Click the button below to make your first login!<br>"
  + "Welcome to Topcon Suite!",
  [ResetPasswordMessages.resetPasswordForgotPasswordInfo]: "Please, enter your new password",
  [ResetPasswordMessages.resetPasswordForgotPasswordMessage]: "Password reset successfully!<br><br>"
  + "Click the button below to make login!"
};
