import { atom, useAtom } from "@libraries/jotai";

import { LoginTheme, lightTheme } from "./themes";

const themeAtom = atom<LoginTheme>(lightTheme);

export default function useTheme() {
  const [theme, setTheme] = useAtom(themeAtom);

  return { theme, setTheme };
}
