import React, { LinkHTMLAttributes } from "react";

import * as Styled from "./styles";

const DataTableButton: React.FC<LinkHTMLAttributes<HTMLAnchorElement>> = ({ children, ...rest }) => (
  <Styled.Container>
    <a {...rest}>{children}</a>
  </Styled.Container>
);

export default DataTableButton;
