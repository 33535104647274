import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import JWTDecode from "jwt-decode";

import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "@hooks/useAuth";

// Telluria
import { Button, useToast } from "@npm-telluria-tecnologia/telluria-ui";

// Atoms
import Logo from "@atoms/TopconLogo";

// Utils
import utils from "@helpers/utils";

// Libraries
import Yup from "@libraries/yup";
import { Button as ButtonMui, IconButton, TextField } from "@libraries/mui/components";
import { Visibility, VisibilityOff } from "@libraries/mui/icons";

// Language
import useTranslation from "@languages/useTranslation";
import { LoginMessages } from "@languages/interfaces/loginMessages";

// Styles
import { ECommandResult } from "@store/enums/commandResult.enum";
import { ForgotPasswordContainer, LoginFormContainer, LoginFormLogo } from "./styles";

interface ITokenData {
  exp: number;
  iat: number;
  nbf: number;
  primarysid: string;
  email: string;
  name: string;
  claims: { [key: string]: string[]; };
  roles: { [key: string]: { [key: string]: string[]; }; };
  modules: { [key: string]: string[]; };
}

interface ISignInFormData {
  email: string;
  password: string;
}

const LoginForm: React.FC = () => {

  const navigate = useNavigate();

  const { signIn } = useAuth();
  const { addToast } = useToast();
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Define the validation types
   */
  const loginSchema = Yup.object().shape({
    email: Yup.string().trim().required(t(LoginMessages.loginRequiredEmail)).email(t(LoginMessages.loginInvalidEmail)),
    password: Yup.string().trim().required(t(LoginMessages.loginPasswordRequired))
  });

  // Form control
  const { control, handleSubmit, formState: { errors: formErrors } } = useForm<ISignInFormData>({
    resolver: yupResolver(loginSchema),
    reValidateMode: "onChange",
    mode: "onBlur"
  });

  /**
   * Login action
   * @param email Email
   * @param password Password
   */
  const logIn = useCallback(async (email: string, password: string) => {

    try {

      setIsLoading(true);

      // Request login access
      const response = await signIn({ email, password });
      const statusToast = utils.getToastStatusType(response.status);

      if (response.status === ECommandResult.SUCCESS) {

        let path = "/";
        const user = JSON.parse(localStorage.getItem("@Topcon:user") as string);
        const tokenData: ITokenData = user.token ? JWTDecode(user.token) : {} as ITokenData;

        // Decode Token to redirect to first allowed module
        if (tokenData && tokenData.modules) {

          // TODO: We are redirecting directly to tech for now until we have permissions on modules
          path = user.admin ? "/maintenanceUser" : "/tech";
        }

        window.location.href = path;
      }

      addToast({ type: statusToast, title: response.message });

    } catch (error) {
      addToast({ type: "info", title: t(LoginMessages.loginFailed) });
    } finally {
      setIsLoading(false);
    }

  }, [signIn, addToast]);

  /**
   * Handle form controllers
   */
  const handleClickShowPassword = () => setShowPassword((state) => !state);
  const handleSubmitForm: SubmitHandler<ISignInFormData> = (formData: ISignInFormData) => logIn(
    formData.email,
    formData.password
  );

  return (
    <LoginFormContainer>
      <LoginFormLogo>
        <Logo />
      </LoginFormLogo>
      <form onSubmit={handleSubmit(handleSubmitForm)} style={{ margin: 20 }}>
        <Controller // Email
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              autoComplete="username"
              label={t(LoginMessages.loginEmailLabel)}
              type="email"
              variant="outlined"
              fullWidth
              size="small"
              error={!!formErrors.email}
              helperText={formErrors.email && formErrors.email.message}
              className="default-field"
            />
          )}
        />
        <Controller // Password
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              autoComplete="current-password"
              label={t(LoginMessages.loginPasswordLabel)}
              type={showPassword ? "text" : "password"}
              variant="outlined"
              fullWidth
              size="small"
              error={!!formErrors.password}
              helperText={formErrors.password && formErrors.password.message}
              className="default-field"
              InputProps={
                {
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  )
                }
              }
            />
          )}
        />
        <Button
          variant="contained"
          size="large"
          type="submit"
          text={t(LoginMessages.loginLoginButton)}
          loading={isLoading}
        />
      </form>
      <ForgotPasswordContainer>
        <ButtonMui variant="text" onClick={() => navigate("forgot-password")}>
          Esqueci minha senha
        </ButtonMui>
      </ForgotPasswordContainer>
    </LoginFormContainer>
  );
};

export default LoginForm;
