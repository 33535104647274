import React from "react";

/** Languages */
import { languages, TLanguages } from "@languages/languageData";
import useTranslation from "@languages/useTranslation";

/** Libraries */
import { MenuItem, Typography } from "@libraries/mui/components";

/** Services */
import api from "@services/rest";

/** Styled components */
import * as Styled from "./styles";

/** Interfaces */
interface IProps {
  anchorElement: null | HTMLElement;
  onClose: (language: TLanguages) => void;
}

const LanguageMenu: React.FC<IProps> = ({ anchorElement, onClose }) => {

  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const handleClick = (language: TLanguages) => {
    i18n.changeLanguage(language);
    api.defaults.headers.common["Accept-Language"] = language;

    onClose(language);
  };

  return (
    <Styled.Container
      sx={{ mt: "45px" }}
      anchorEl={anchorElement}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      open={Boolean(anchorElement)}
      onClose={onClose}
    >
      {languages.map((language) => (
        <MenuItem
          key={language.language}
          onClick={() => handleClick(language.language)}
          className={currentLanguage === language.language ? "selected" : ""}
        >
          <Typography textAlign="center">{language.description}</Typography>
        </MenuItem>
      ))}
    </Styled.Container>
  );
};

export default LanguageMenu;
