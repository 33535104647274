export enum ResetPasswordMessages {
  resetPasswordLabel = "reset.password.label",
  resetPasswordButton = "reset.password.button",
  resetPasswordGoToLoginPageButton = "reset.password.goToLoginPage.button",
  resetPasswordRequired = "reset.password.required",
  resetPasswordFailed = "reset.password.failed",
  resetPasswordConfirmAccountInfo = "reset.password.confirmAccount.info",
  resetPasswordConfirmAccountMessage = "reset.password.confirmAccount.message",
  resetPasswordForgotPasswordInfo = "reset.password.forgotPassword.info",
  resetPasswordForgotPasswordMessage = "reset.password.forgotPassword.message",
}

export interface TranslationResetPasswordMessages {
  [ResetPasswordMessages.resetPasswordLabel]: string;
  [ResetPasswordMessages.resetPasswordButton]: string;
  [ResetPasswordMessages.resetPasswordGoToLoginPageButton]: string;
  [ResetPasswordMessages.resetPasswordRequired]: string;
  [ResetPasswordMessages.resetPasswordFailed]: string;
  [ResetPasswordMessages.resetPasswordConfirmAccountInfo]: string;
  [ResetPasswordMessages.resetPasswordConfirmAccountMessage]: string;
  [ResetPasswordMessages.resetPasswordForgotPasswordInfo]: string;
  [ResetPasswordMessages.resetPasswordForgotPasswordMessage]: string;
}
