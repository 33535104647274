import React, { useCallback } from "react";

// region External libraries
import * as _ from "lodash";
import Yup from "@libraries/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, useToast } from "@npm-telluria-tecnologia/telluria-ui";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Checkbox, FormControlLabel, TextField } from "@libraries/mui/components";
// endregion External libraries
// region Services
import api from "@services/rest";
// endregion Services
// region Store
import { ECommandResult } from "@store/enums/commandResult.enum";
// endregion Store
// region Atoms
// endregion Atoms
// region Organisms
import { TModeForm, UserType } from "@organisms/MaintenanceUser";
// endregion Organisms
// region Languages
import useTranslation from "@languages/useTranslation";
import { UserFormMessages } from "@languages/interfaces";
// endregion Languages
// region Helpers
import { handleErrorMessage } from "@helpers/handleErrorMessage";
import utils from "@helpers/utils";
// endregion Helpers
// region Styled components
import * as Styled from "./styles";
// endregion Styled components

// region Interfaces
interface IFormInputs {
  fullName: string;
  email: string;
  active: boolean;
}

interface IFormUserProps {
  currentUser?: UserType | null;
  onSave?: (user: UserType, mode: TModeForm) => void;
  isMaintenance?: boolean;
  mode?: any;
}

// endregion Interfaces
// region Constants
const formSchema = Yup.object().shape({
  fullName: Yup.string().required(),
  email: Yup.string().email().required()
});
// endregion Constants

/** Component */
const FormUser: React.FC<IFormUserProps> = ({
  currentUser, onSave, isMaintenance
}) => {
  // region Language
  const { t } = useTranslation();
  // endregion Language
  // region Requests
  /**
   * Create the user
   * @param user
   */
  const createUser = useCallback(async (form : IFormInputs) => {
    const requestBody = {
      "fullName": form.fullName,
      "email": form.email,
      "domain": utils.toPascalCase(window.location.hostname.split(".")[0])
    };
    const response = await api.post("/auth/register", requestBody);

    return response.data;
  }, []);

  /**
   * Update the user
   * @param user
   */
  const updateUser = useCallback(async (form : IFormInputs) => {
    const requestBody = {
      ...form,
      id: currentUser?.id
    };

    const response = await api.patch("/user/update", requestBody);

    return response.data;
  }, []);
  // region Hooks
  const { addToast } = useToast();
  const { control, setValue, handleSubmit, formState: { errors: formErrors } } = useForm<IFormInputs>({
    resolver: yupResolver(formSchema),
    reValidateMode: "onChange",
    mode: "onBlur"
  });
  // endregion Hooks

  // region States
  const [isLoadingSaveUser, setIsLoadingSaveUser] = React.useState(false);
  // endregion States

  // region Form handlers
  const handleSubmitForm: SubmitHandler<IFormInputs> = (async (data: IFormInputs) => {
    try {

      setIsLoadingSaveUser(true);

      const saveMode = !_.isEmpty(currentUser) ? "UPDATE" : "CREATE";
      const response = saveMode === "CREATE" ? await createUser(data) : await updateUser(data);

      if (response?.status !== ECommandResult.SUCCESS) return;

      addToast({ type: "success", title: response.message });

      if (onSave) onSave(response?.result as UserType, saveMode);

    } catch (error) {
      addToast({ type: "error", title: "Internal client error", description: error.message });
    } finally {
      setIsLoadingSaveUser(false);
    }
  });
  // endregion Form handlers

  // region Effects

  // Fill form with current user data (In case of editing)
  React.useEffect(() => {

    if (!currentUser) return;

    setValue("fullName", currentUser.fullName);
    setValue("email", currentUser.email);
    setValue("active", currentUser.active ?? true);

  }, [currentUser, setValue]);

  // endregion Effects

  return (
    <Styled.FormContainer onSubmit={(e) => utils.preventDefaultFormSubmit(e, handleSubmit(handleSubmitForm))}>
      <Controller
        name="fullName"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            autoComplete="off"
            fullWidth
            label={t(UserFormMessages.fieldFullName, t)}
            variant="outlined"
            error={!!formErrors.fullName}
            helperText={handleErrorMessage(formErrors.fullName, t)}
            className="default-field"
            size="small"
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            autoComplete="off"
            fullWidth
            label={t(UserFormMessages.fieldEmail, t)}
            variant="outlined"
            error={!!formErrors.email}
            helperText={handleErrorMessage(formErrors.email, t)}
            className="default-field"
            size="small"
            disabled={!_.isEmpty(currentUser)}
          />
        )}
      />
      <Controller
        name="active"
        control={control}
        defaultValue
        render={({ field }) => (
          <FormControlLabel
            {...field}
            control={<Checkbox checked={field.value} size="small" />}
            label={t(field.value
              ? UserFormMessages.fieldActive
              : UserFormMessages.fieldInactive).toString()}
            sx={{ visibility: isMaintenance ? "visible" : "hidden", alignSelf: "flex-start" }}
            disabled={_.isEmpty(currentUser)}
          />
        )}
      />
      <Button
        text={t(UserFormMessages.buttonSave)}
        type="submit"
        className="default-submit-button"
        loading={isLoadingSaveUser}
      />
    </Styled.FormContainer>
  );
};

FormUser.defaultProps = {
  onSave: undefined,
  currentUser: null,
  isMaintenance: false,
  mode: undefined
};

export default FormUser;