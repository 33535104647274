import { YupMessages, TranslationYupMessages } from "../interfaces/yupMessages";

export const messages: TranslationYupMessages = {
  [YupMessages.mixedDefault]: "Campo inválido",
  [YupMessages.mixedRequired]: "Campo obrigatório",
  [YupMessages.mixedOneOf]: "Campo deve ser um dos seguintes valores: {{values}}",
  [YupMessages.mixedNotOneOf]: "Campo não deve ser um dos seguintes valores: {{values}}",
  [YupMessages.mixedNotType]: "Campo obrigatório",

  [YupMessages.stringLength]: "O campo deve ter exatamente {{length}} caracteres",
  [YupMessages.stringMin]: "O campo deve ter pelo menos {{min}} caracteres",
  [YupMessages.stringMax]: "O campo deve ter no máximo {{max}} caracteres",
  [YupMessages.stringMatches]: "O campo deve corresponder a seguinte regra: \"{{regex}}\"",
  [YupMessages.stringEmail]: "E-mail inválido",
  [YupMessages.stringUrl]: "URL inválida",
  [YupMessages.stringTrim]: "O campo não deve conter espaços no início ou no fim",
  [YupMessages.stringLowerCase]: "O campo deve estar em minúsculo",
  [YupMessages.stringUpperCase]: "O campo deve estar em maiúsculo",

  [YupMessages.numberMin]: "O campo deve ser maior ou igual a {{min}}",
  [YupMessages.numberMax]: "O campo deve ser menor ou igual a {{max}}",
  [YupMessages.numberLessThan]: "O campo deve ser menor que {{less}}",
  [YupMessages.numberMoreThan]: "O campo deve ser maior que {{more}}",
  [YupMessages.numberPositive]: "O campo deve ser um número positivo",
  [YupMessages.numberNegative]: "O campo deve ser um número negativo",
  [YupMessages.numberInteger]: "O campo deve ser um número inteiro",

  [YupMessages.dateMin]: "A data deve ser posterior a {{min}}",
  [YupMessages.dateMax]: "A data deve ser anterior ou igual ao dia atual",

  [YupMessages.objectNoUnknown]: "O campo tem chaves não especificadas: {{unknown}}",

  [YupMessages.arrayMin]: "O campo deve ter pelo menos {{min}} item(ns)",
  [YupMessages.arrayMax]: "O campo deve ter menos que {{max}} itens"
};
