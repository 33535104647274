import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Parser from "html-react-parser";

import { yupResolver } from "@hookform/resolvers/yup";

// region Telluria
import { Button, useToast } from "@npm-telluria-tecnologia/telluria-ui";
// endregion Telluria
// region Atoms
import Logo from "@atoms/TopconLogo";
// endregion Atoms
// region Assets
import CheckGif from "@assets/gifs/checkOk.gif";
// endregion Assets
// region Store
import utils from "@helpers/utils";
// endregion Store
// region Libraries
import Yup from "@libraries/yup";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@libraries/mui/components";
// endregion Libraries
// region Language
import useTranslation from "@languages/useTranslation";
import { ForgotPasswordMessages, ResetPasswordMessages } from "@languages/interfaces";
// endregion Language
// region Styles
import api from "@services/rest";
import { ECommandResult } from "@store/enums/commandResult.enum";
import {
  ForgotPasswordDialogContainer,
  ForgotPasswordFormContainer,
  ForgotPasswordFormLogo,
  ForgotPasswordInfoContainer
} from "./styles";

// endregion Styles

interface IForgotPasswordFormData {
  email: string;
}

const ForgotPasswordForm: React.FC = () => {

  const navigate = useNavigate();

  const { addToast } = useToast();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  /**
   * Define the validation types
   */
  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().trim().required(t(ForgotPasswordMessages.forgotPasswordRequiredEmail))
      .email(t(ForgotPasswordMessages.forgotPasswordInvalidEmail))
  });

  // Form control
  const { control, handleSubmit, formState: { errors: formErrors } } = useForm<IForgotPasswordFormData>({
    resolver: yupResolver(forgotPasswordSchema),
    reValidateMode: "onChange",
    mode: "onBlur"
  });

  /**
   * Send the forgot password email
   * @param email The email to send the forgot password email
   */
  const sendResetPasswordMail = useCallback(async (email: string) => {

    setIsLoading(true);

    try {

      const domain = utils.toPascalCase(window.location.hostname.split(".")[0]);
      const response = await api.post("/auth/forgotpassword", { email, domain }) as any;
      const responseData = response.data;
      const statusToast = utils.getToastStatusType(responseData.status);

      if (responseData.status === ECommandResult.SUCCESS) setDialogOpen(true);
      else addToast({ type: statusToast, title: responseData.message, duration: 20000 });

    } catch (error) {
      addToast({ type: "info", title: t(ResetPasswordMessages.resetPasswordFailed) });
    } finally {
      setIsLoading(false);
    }

  }, [addToast]);

  /**
   * Handle form controllers
   */
  const handleSubmitForm: SubmitHandler<IForgotPasswordFormData> = (formData: IForgotPasswordFormData) => sendResetPasswordMail(
    formData.email
  );

  return (
    <>
      <ForgotPasswordFormContainer>
        <ForgotPasswordFormLogo>
          <Logo />
        </ForgotPasswordFormLogo>
        <ForgotPasswordInfoContainer>
          {Parser(t(ForgotPasswordMessages.forgotPasswordInfo))}
        </ForgotPasswordInfoContainer>
        <form onSubmit={handleSubmit(handleSubmitForm)} style={{ margin: 20 }}>
          <Controller // Email
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t(ForgotPasswordMessages.forgotPasswordEmailLabel)}
                variant="outlined"
                fullWidth
                size="small"
                error={!!formErrors.email}
                helperText={formErrors.email && formErrors.email.message}
                className="default-field"
              />
            )}
          />
          <Button
            variant="contained"
            size="large"
            type="submit"
            text={t(ForgotPasswordMessages.forgotPasswordButton)}
            loading={isLoading}
          />
        </form>
      </ForgotPasswordFormContainer>
      <ForgotPasswordDialogContainer className="dialog">
        <Dialog
          container={() => document.querySelector(".dialog") as HTMLElement}
          fullWidth
          open={dialogOpen}
        >
          <DialogTitle className="dialog__title"><img src={CheckGif} alt="Check" /></DialogTitle>
          <DialogContent className="dialog__content">
            <DialogContentText>
              {t(ForgotPasswordMessages.forgotPasswordMessage)}
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog__actions">
            <Button
              variant="contained"
              size="large"
              type="submit"
              onClick={() => navigate("/")}
              text={t(ForgotPasswordMessages.forgotPasswordGoToLoginPageButton)}
            />
          </DialogActions>
        </Dialog>
      </ForgotPasswordDialogContainer>
    </>
  );
};

export default ForgotPasswordForm;
