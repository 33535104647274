import { DataTableMessages, TranslationDataTableMessages } from "../interfaces/dataTableMessages";

export const messages: TranslationDataTableMessages = {
  [DataTableMessages.dtEmptyTable]: "Nenhum registro encontrado",
  [DataTableMessages.dtInfo]: "Mostrando _START_ a _END_ de _TOTAL_ registros",
  [DataTableMessages.dtInfoEmpty]: "Mostrando 0 a 0 de 0 registros",
  [DataTableMessages.dtInfoFiltered]: "(Filtrado de _MAX_ registros totais)",
  [DataTableMessages.dtLengthMenu]: "_MENU_ registros por página",
  [DataTableMessages.dtLoadingRecords]: "Carregando...",
  [DataTableMessages.dtProcessing]: "Processando...",
  [DataTableMessages.dtZeroRecords]: "Nenhum registro encontrado",
  [DataTableMessages.dtSearchPlaceholder]: "Buscar..."
};
