import { TFunction } from "@languages/useTranslation";

/**
 * Translate error message based on language
 * @param fieldError - The field error to be translated
 * @param t - The translation function
 * @returns Translated field error
 */
export const handleErrorMessage = (fieldError: any, t: TFunction<"translation", undefined>) => {
  if (!fieldError) return "";

  return t(fieldError.message.key, fieldError.message.values);
};
