import {
  LoginMessages,
  TranslationLoginMessages
} from "../interfaces/loginMessages";

// eslint-disable-next-line import/prefer-default-export
export const messages: TranslationLoginMessages = {
  [LoginMessages.loginCopyright]: "Todos os direitos reservados | {{year}}",
  [LoginMessages.loginEmailLabel]: "E-mail",
  [LoginMessages.loginPasswordLabel]: "Senha",
  [LoginMessages.loginTopconDevelopment]: "Topcon é desenvolvido pelo Grupo Telluria",
  [LoginMessages.loginLoginButton]: "Entrar",
  [LoginMessages.loginPasswordRequired]: "Senha obrigatória",
  [LoginMessages.loginInvalidEmail]: "E-mail inválido",
  [LoginMessages.loginRequiredEmail]: "E-mail obrigatório",
  [LoginMessages.loginFailed]: "Falha ao entrar, verifique seus dados e tente novamente"
};
