import { MaintenanceUserMessages, TranslationMaintenanceUserMessages } from "@languages/interfaces/maintenanceUserMessages";

export const messages: TranslationMaintenanceUserMessages = {
  [MaintenanceUserMessages.columnFullName]: "Nome completo",
  [MaintenanceUserMessages.columnEmail]: "E-mail",
  [MaintenanceUserMessages.columnAdmin]: "Administrador",
  [MaintenanceUserMessages.columnActive]: "Estado",
  [MaintenanceUserMessages.columnCreatedAt]: "Criado em",
  [MaintenanceUserMessages.columnUpdatedAt]: "Ultima atualização",
  [MaintenanceUserMessages.dialogDeleteTitle]: "Excluir usuário"
};
