import { windowWidth } from "@styles/global";
import styled from "styled-components";

const TelluriaGroupContainer = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .logo__svg {
    fill: white;
    width: 100%;

    @media (max-width: ${windowWidth.tablet}){
      width: 80% !important;
    }
    @media (max-height: ${windowWidth.tablet}) and (min-width: ${windowWidth.tablet}){
      width: 60% !important;
    }
  }

`;

export default TelluriaGroupContainer;
