import { DataTableMessages, TranslationDataTableMessages } from "../interfaces/dataTableMessages";

export const messages: TranslationDataTableMessages = {
  [DataTableMessages.dtEmptyTable]: "No records found",
  [DataTableMessages.dtInfo]: "Showing _START_ to _END_ of _TOTAL_ entries",
  [DataTableMessages.dtInfoEmpty]: "Showing 0 to 0 of 0 entries",
  [DataTableMessages.dtInfoFiltered]: "(Filtered from _MAX_ total entries)",
  [DataTableMessages.dtLengthMenu]: "_MENU_ entries per page",
  [DataTableMessages.dtLoadingRecords]: "Loading...",
  [DataTableMessages.dtProcessing]: "Processing...",
  [DataTableMessages.dtZeroRecords]: "No matching records found",
  [DataTableMessages.dtSearchPlaceholder]: "Search..."
};
