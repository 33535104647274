import styled from "styled-components";
import { ReactSVG } from "react-svg";

import { windowWidth } from "@styles/global";

const Container = styled.nav`
  background-color: ${(props) => props.theme.colors.background};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px 8px 15px;
  transition: all 0.5s;
  width: 100%;
  color: ${(props) => props.theme.colors.primary};

  @media ( max-width: ${windowWidth.mobile.large} ) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const ProductIcon = styled(ReactSVG)`

  width: 200px;

  svg {
    width: 100%;
    height: 100%;
  }

  @media ( max-width: ${windowWidth.laptop.large} ) {
    width: 180px;
  }

  @media ( max-width: ${windowWidth.mobile.large} ) {
    width: 150px;
  }

  @media ( max-width: ${windowWidth.mobile.small} ) {
    width: 120px;
  }
`;

export { Container, ProductIcon };
