import { MaintenanceUserMessages, TranslationMaintenanceUserMessages } from "@languages/interfaces/maintenanceUserMessages";

export const messages: TranslationMaintenanceUserMessages = {
  [MaintenanceUserMessages.columnFullName]: "Nombre completo",
  [MaintenanceUserMessages.columnEmail]: "Email",
  [MaintenanceUserMessages.columnAdmin]: "Administrador",
  [MaintenanceUserMessages.columnActive]: "Estado",
  [MaintenanceUserMessages.columnCreatedAt]: "Creado en",
  [MaintenanceUserMessages.columnUpdatedAt]: "Última actualización",
  [MaintenanceUserMessages.dialogDeleteTitle]: "Eliminar usuario"
};
