import { ForgotPasswordMessages, TranslationForgotPasswordMessages } from "../interfaces/forgotPasswordMessages";

export const messages: TranslationForgotPasswordMessages = {
  [ForgotPasswordMessages.forgotPasswordEmailLabel]: "Email",
  [ForgotPasswordMessages.forgotPasswordRequiredEmail]: "Correo electronico es requerido",
  [ForgotPasswordMessages.forgotPasswordInvalidEmail]: "Correo electronico invalido",
  [ForgotPasswordMessages.forgotPasswordButton]: "Enviar correo electronico",
  [ForgotPasswordMessages.forgotPasswordGoToLoginPageButton]: "Ir a la pagina de inicio de sesion",
  [ForgotPasswordMessages.forgotPasswordInfo]: "Ingrese su correo electronico y le enviaremos <br> un enlace para"
  + " restablecer su contraseña.",
  [ForgotPasswordMessages.forgotPasswordMessage]: "Le hemos enviado un correo electronico con un enlace para restablecer"
};
