/* eslint-disable react/no-array-index-key */

import React, { useCallback, useEffect, useState } from "react";

/** External libraries */
import * as _ from "lodash";
import { Button, useToast } from "@npm-telluria-tecnologia/telluria-ui/dist";

/** Languages */
import { FilterMessages, GlobalMessages } from "@languages/interfaces";
import useTranslation from "@languages/useTranslation";

/** Libraries */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@libraries/mui/components";
import { Close } from "@libraries/mui/icons";

/** Styles */
import * as Styled from "./styles";

/** Enums */
enum EFilterOperatorsType {
  EQUAL = "equal",
  NOT_EQUAL = "notEqual",
  GREATER_THAN = "greaterThan",
  GREATER_THAN_OR_EQUAL = "greaterThanOrEqual",
  LESS_THAN = "lessThan",
  LESS_THAN_OR_EQUAL = "lessThanOrEqual",
  CONTAINS = "contains",
  NOT_CONTAINS = "notContains",
  STARTS_WITH = "startsWith"
}

/** Interfaces */
export interface IFilterField {
  propName: string;
  visualName: string;
  operator?: IOperator | null;
  value?: string | number;
}

interface IOperator {
  propName: EFilterOperatorsType;
  visualName: string;
}

interface IProps<T> {
  filterProperties: IFilterField[];
  filterData: T[];
  open: boolean;
  title: string;
  onClose: () => void;
  onFilter: (filteredData: T[]) => void;
}

const Filter: React.FC<IProps<any>> = ({ filterProperties, filterData, open, onClose, onFilter, title }) => {

  const { t, i18n } = useTranslation();
  const { addToast } = useToast();

  const operatorsControl = [
    { propName: EFilterOperatorsType.EQUAL, visualName: t(FilterMessages.operatorEqual) },
    { propName: EFilterOperatorsType.NOT_EQUAL, visualName: t(FilterMessages.operatorNotEqual) },
    { propName: EFilterOperatorsType.GREATER_THAN, visualName: t(FilterMessages.operatorGreater) },
    { propName: EFilterOperatorsType.GREATER_THAN_OR_EQUAL, visualName: t(FilterMessages.operatorGreaterOrEqual) },
    { propName: EFilterOperatorsType.LESS_THAN, visualName: t(FilterMessages.operatorLess) },
    { propName: EFilterOperatorsType.LESS_THAN_OR_EQUAL, visualName: t(FilterMessages.operatorLessOrEqual) },
    { propName: EFilterOperatorsType.CONTAINS, visualName: t(FilterMessages.operatorContains) },
    { propName: EFilterOperatorsType.NOT_CONTAINS, visualName: t(FilterMessages.operatorNotContains) },
    { propName: EFilterOperatorsType.STARTS_WITH, visualName: t(FilterMessages.operatorStartsWith) }
  ];

  const [filterFields, setFilterFields] = useState<IFilterField[]>([]); // List of filter fields
  const [stateAccordion, setStateAccordion] = useState<{ [index: string]: boolean }>({}); // Accordion collapsed state
  const [stateOperators, setStateOperators] = useState<IOperator[]>(operatorsControl); // Operators to be used in
  // the filter

  /** Control event of collapse panels
   * @param key Panel id to change
   */
  const handleAccordion = useCallback((key: string) => {
    setStateAccordion({ ...stateAccordion, [key]: !stateAccordion[key] });
  }, [stateAccordion]);

  /** Remove all filter fields */
  const handleRemoveAllFilterFields = useCallback(() => {

    setStateAccordion({});
    setFilterFields([]);

  }, [setFilterFields]);

  /** Remove specific filter field */
  const handleRemoveFilterField = useCallback((index: number) => {

    const newFilterFields = [...filterFields];

    newFilterFields[index] = {} as IFilterField;

    setFilterFields(newFilterFields);

  }, [filterFields, setFilterFields]);

  /** Add new filter field (Empty) */
  const handleAddFilterField = useCallback(() => {

    let countFilters = 0;

    // Prevent add more filter fields than properties
    filterFields.forEach((filterProperty) => {
      if (!_.isEmpty(filterProperty)) countFilters += 1;
    });

    if (countFilters >= filterProperties.length) {

      addToast({ type: "info", title: t(FilterMessages.infoMaxFilterFields) });

      return;
    }

    setFilterFields((prevState) => [
      ...prevState, {
        value: "",
        propName: "",
        visualName: t(FilterMessages.infoNewFieldFilter)
      }
    ]);

  }, [addToast, filterFields, setFilterFields]);

  /** Update existing filter field (With data) */
  const handleSubmitFormField = useCallback((event, index) => {

    event.preventDefault();

    const propName = event.target[`propName-${index}`].value;
    const propVisualName = propName && filterProperties.find((field) => (field.propName === propName))?.visualName;
    const operator = stateOperators.find(
      (_operator) => (_operator.propName === event.target[`operator-${index}`].value)
    );
    const content = event.target[`value-${index}`].value;

    // Validate all fields of this filter form
    if (!propName || !operator || !content) {

      // TODO: Add language for this message title
      addToast({ type: "info", title: t(FilterMessages.infoRequiredField), overwrite: true });

      return;
    }

    // Update filter fields
    setFilterFields((prevState) => prevState.map((_field, i) => {

      if (i === index) {
        return {
          propName,
          visualName: propVisualName,
          operator,
          value: content
        };
      }

      return _field;
    }));

  }, [addToast, filterProperties, stateOperators, setFilterFields]);

  /** Filter data */
  const handleFilter = useCallback(() => {

    const filteredData = filterData.filter((data) => filterFields.every((filterField) => {

      if (_.isEmpty(filterField)) return true;

      const fieldValue = filterField.propName.split(".").reduce((obj, key) => obj[key], data);
      const { operator, value } = filterField;

      if (!operator || !value) return true;

      switch (operator.propName) {

        case EFilterOperatorsType.EQUAL:
          return fieldValue === value;
        case EFilterOperatorsType.NOT_EQUAL:
          return fieldValue !== value;
        case EFilterOperatorsType.GREATER_THAN:
          return fieldValue > value;
        case EFilterOperatorsType.GREATER_THAN_OR_EQUAL:
          return fieldValue >= value;
        case EFilterOperatorsType.LESS_THAN:
          return fieldValue < value;
        case EFilterOperatorsType.LESS_THAN_OR_EQUAL:
          return fieldValue <= value;
        case EFilterOperatorsType.CONTAINS:
          return fieldValue.toString().toLowerCase().includes(value.toString().toLowerCase());
        case EFilterOperatorsType.NOT_CONTAINS:
          return !fieldValue.toString().toLowerCase().includes(value.toString().toLowerCase());
        case EFilterOperatorsType.STARTS_WITH:
          return fieldValue.toString().toLowerCase().startsWith(value.toString().toLowerCase());

        default:
          return true;
      }
    }));

    onFilter(filteredData);
    onClose();

  }, [filterData, filterFields]);

  useEffect(() => {
    setStateOperators(operatorsControl);
  }, [i18n.language]);

  return (
    <Styled.Container anchor="right" open={open} onClose={onClose}>
      <Styled.Content>
        <Styled.Title>{t(GlobalMessages.filtersText)}</Styled.Title>
        <Styled.InfoFilter>
          <div className="title">{t(FilterMessages.infoAppliedText)}</div>
          <div className="content">{title}</div>
        </Styled.InfoFilter>
        <Styled.Fields>
          <div className="info">{t(FilterMessages.infoCorrespondenceText)}</div>
          <div className="fields">
            {filterFields.length > 0 ? filterFields.map((filterField, index) => (
              !_.isEmpty(filterField) && (
                <Accordion
                  className="field"
                  key={`filter-field-${index}`}
                  expanded={stateAccordion[`filter-field-${index}`]}
                  onChange={() => handleAccordion(`filter-field-${index}`)}
                >
                  <AccordionSummary>
                    <div className="title">
                      <div className="field_name">{filterField.visualName}</div>
                      <div
                        className="field_icon--close"
                        aria-hidden="true"
                        onClick={() => handleRemoveFilterField(index)}
                      >
                        <Close />
                      </div>
                    </div>
                    <Typography>
                      <div className="content operator">{filterField.operator?.visualName}</div>
                      <div className="content">{filterField.value}</div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Styled.FormFilterField onSubmit={(formData) => handleSubmitFormField(formData, index)}>
                      <FormControl className="default-field" fullWidth size="small">
                        <InputLabel id={`field-${index}`}>Propriedade</InputLabel>
                        <Select
                          labelId={`field-${index}`}
                          label="Propriedade"
                          variant="outlined"
                          defaultValue={filterField.propName || ""}
                          name={`propName-${index}`}
                          size="small"
                        >
                          {filterProperties.map((prop) => (
                            <MenuItem
                              key={prop.propName}
                              value={prop.propName}
                              aria-label={prop.visualName}
                              disabled={!!filterFields.find((field) => (field.propName === prop.propName))}
                            >
                              {prop.visualName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl className="default-field" fullWidth size="small">
                        <InputLabel id={`operator-${index}`}>Operador</InputLabel>
                        <Select
                          labelId={`operator-${index}`}
                          label="Operador"
                          variant="outlined"
                          defaultValue={filterField.operator?.propName || ""}
                          name={`operator-${index}`}
                          size="small"
                        >
                          {stateOperators.map((operator) => (
                            <MenuItem key={operator.propName} value={operator.propName}>{operator.visualName}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        label="Valor"
                        fullWidth
                        variant="outlined"
                        defaultValue={filterField.value || ""}
                        name={`value-${index}`}
                        className="default-field"
                        size="small"
                      />
                      <Button
                        disableRipple
                        text={t(FilterMessages.buttonSaveFieldFilter)}
                        type="submit"
                        className="default-submit-button"
                      />
                    </Styled.FormFilterField>
                  </AccordionDetails>
                </Accordion>
              )
            )) : (<div className="no-filter">{t(FilterMessages.infoNoFiltersApplied)}</div>)}
          </div>
        </Styled.Fields>
        <Styled.Actions>
          <Button disableRipple text={t(FilterMessages.buttonAddFieldsFilter)} onClick={() => handleAddFilterField()} />
          <Button
            disableRipple
            text={t(FilterMessages.buttonRemoveAllFieldsFilter)}
            onClick={handleRemoveAllFilterFields}
          />
        </Styled.Actions>
        <Button className="default-submit-button" text={t(FilterMessages.buttonApplyFilter)} onClick={handleFilter} />
      </Styled.Content>
    </Styled.Container>
  );
};

export default Filter;
