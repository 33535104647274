export enum DataTableMessages {
  dtEmptyTable = "dataTable.settings.dtEmptyTable",
  dtInfo = "dataTable.settings.dtInfo",
  dtInfoEmpty = "dataTable.settings.dtInfoEmpty",
  dtInfoFiltered = "dataTable.settings.dtInfoFiltered",
  dtLengthMenu = "dataTable.settings.dtLengthMenu",
  dtLoadingRecords = "dataTable.settings.dtLoadingRecords",
  dtProcessing = "dataTable.settings.dtProcessing",
  dtZeroRecords = "dataTable.settings.dtZeroRecords",
  dtSearchPlaceholder = "dataTable.settings.dtSearchPlaceholder",
}

export interface TranslationDataTableMessages {
  [DataTableMessages.dtEmptyTable]: string;
  [DataTableMessages.dtInfo]: string;
  [DataTableMessages.dtInfoEmpty]: string;
  [DataTableMessages.dtInfoFiltered]: string;
  [DataTableMessages.dtLengthMenu]: string;
  [DataTableMessages.dtLoadingRecords]: string;
  [DataTableMessages.dtProcessing]: string;
  [DataTableMessages.dtZeroRecords]: string;
  [DataTableMessages.dtSearchPlaceholder]: string;
}
