import React from "react";
import ResetPasswordForm from "@molecules/ResetPasswordForm";
import { ResetPasswordContainer, ResetPasswordFormContainer } from "./styles";

export type IResetPasswordProps = {
  type: "confirm-account" | "reset-password";
}

const ResetPassword: React.FC<IResetPasswordProps> = ({ type }) => (
  <ResetPasswordContainer>
    <ResetPasswordFormContainer>
      <ResetPasswordForm type={type} />
    </ResetPasswordFormContainer>
  </ResetPasswordContainer>
);

export default ResetPassword;
