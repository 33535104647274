import React from "react";

/** Atoms */
import ModuleMenuItem from "@atoms/ModuleMenuItem";

/** Libraries */
import { MenuItem, Typography } from "@libraries/mui/components";

/** Assets */
import TechLogo from "@assets/TopconLogo/Tech.svg";
import CustomerLogo from "@assets/TopconLogo/Customer.svg";
import FleetLogo from "@assets/TopconLogo/Fleet.svg";
import BILogo from "@assets/TopconLogo/BI.svg";

import * as Styled from "./styles";

/** Interfaces */
interface IProps {
  anchorElement: null | HTMLElement;
  onClose: () => void;
}

interface IModule {
  name: string;
  path: string;
  svgLogo: string;
  color: string;
}

const modules: IModule[] = [
  {
    name: "TECH",
    path: "/tech",
    svgLogo: TechLogo,
    color: "#999999"
  },
  {
    name: "CUSTOMER",
    path: "/customer",
    svgLogo: CustomerLogo,
    color: "#e3f377"
  },
  {
    name: "FLEET",
    path: "/fleet",
    svgLogo: FleetLogo,
    color: "#fe6565"
  },
  {
    name: "BI",
    path: "/bi",
    svgLogo: BILogo,
    color: "#fecb33"
  }
];

const ModuleMenu: React.FC<IProps> = ({ anchorElement, onClose }) => {

  const handleClick = (path: string) => window.open(path);

  return (
    <Styled.Container
      sx={{ mt: "45px" }}
      anchorEl={anchorElement}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      open={Boolean(anchorElement)}
      onClose={onClose}
    >
      {modules.map((module) => (
        <MenuItem
          key={module.name}
          onClick={() => handleClick(module.path)}
        >
          <Typography textAlign="center">
            <ModuleMenuItem logoSvg={module.svgLogo} name={module.name} color={module.color} />
          </Typography>
        </MenuItem>
      ))}
    </Styled.Container>
  );
};

export default ModuleMenu;
