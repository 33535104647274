import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Parser from "html-react-parser";

import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "@hooks/useAuth";

// region Telluria
import { Button, useToast } from "@npm-telluria-tecnologia/telluria-ui";
// endregion Telluria
// region Store
import { ECommandResult } from "@store/enums/commandResult.enum";
import utils from "@helpers/utils";
// endregion Store
// region Atoms
import Logo from "@atoms/TopconLogo";
// endregion Atoms
// region Pages
import { IResetPasswordProps } from "@pages/ResetPassword";
// endregion Pages
// region Assets
import CheckGif from "@assets/gifs/checkOk.gif";
// endregion Assets
// region Libraries
import Yup from "@libraries/yup";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField
} from "@libraries/mui/components";
import { Visibility, VisibilityOff } from "@libraries/mui/icons";
// endregion Libraries
// region Language
import useTranslation from "@languages/useTranslation";
import { ResetPasswordMessages } from "@languages/interfaces";
// endregion Language
// region Styles
import { EModules } from "@store/enums/modules.enum";
import {
  ResetPasswordDialogContainer,
  ResetPasswordFormContainer,
  ResetPasswordFormLogo,
  ResetPasswordInfoContainer
} from "./styles";

// endregion Styles

interface IQueryParams {
  userId: string;
  code: string;
  module: string;
}

interface IResetPasswordFormData {
  password: string;
}

const ResetPasswordForm: React.FC<IResetPasswordProps> = ({ type }) => {

  const navigate = useNavigate();

  const { addToast } = useToast();
  const { t } = useTranslation();
  const { confirmNewPassword: confirmNewPasswordAuth } = useAuth();

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [queryParams, setQueryParams] = useState<IQueryParams>({} as IQueryParams);

  /**
   * Define the validation types
   */
  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string().trim().required(t(ResetPasswordMessages.resetPasswordRequired))
  });

  // Form control
  const { control, handleSubmit, formState: { errors: formErrors } } = useForm<IResetPasswordFormData>({
    resolver: yupResolver(resetPasswordSchema),
    reValidateMode: "onChange",
    mode: "onBlur"
  });

  /**
   * Confirm new password action
   * @param password New password
   */
  const confirmNewPassword = useCallback(async (password: string) => {

    const queryParams = Object.fromEntries(new URLSearchParams(window.location.search)) as unknown as IQueryParams;

    try {

      setIsLoading(true);

      // Request new password
      const response = await confirmNewPasswordAuth(
        {
          idUser: queryParams.userId,
          confirmationToken: queryParams?.code,
          newPassword: password,
          type
        }
      );

      const { status, message } = response.data;
      const statusToast = utils.getToastStatusType(response.status);

      // Toast just on error / alert - Success is open on modal
      if (status !== ECommandResult.SUCCESS) {

        addToast({
          type: statusToast,
          title: message
        });

      } else {
        setDialogOpen(true);
      }

    } catch (error) {
      addToast({ type: "info", title: t(ResetPasswordMessages.resetPasswordFailed) });
    } finally {

      setIsLoading(false);
      setQueryParams(queryParams);
    }

  }, [addToast]);

  /**
   * Handle form controllers
   */
  const handleClickShowPassword = () => setShowPassword((state) => !state);
  const handleRedirectLoginPage = () => {

    if (queryParams.module === EModules.TOPCON_CUSTOMER) {
      window.location.href = `${window.location.origin}/customer/login`;
    } else {
      navigate("/");
    }
  };
  const handleSubmitForm: SubmitHandler<IResetPasswordFormData> = (formData: IResetPasswordFormData) => confirmNewPassword(
    formData.password
  );

  return (
    <>
      <ResetPasswordFormContainer>
        <ResetPasswordFormLogo>
          <Logo />
        </ResetPasswordFormLogo>
        <ResetPasswordInfoContainer>
          {type === "confirm-account"
            ? Parser(t(ResetPasswordMessages.resetPasswordConfirmAccountInfo))
            : Parser(t(ResetPasswordMessages.resetPasswordForgotPasswordInfo))}
        </ResetPasswordInfoContainer>
        <form onSubmit={handleSubmit(handleSubmitForm)} style={{ margin: 20 }}>
          <Controller // Password
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t(ResetPasswordMessages.resetPasswordLabel)}
                type={showPassword ? "text" : "password"}
                variant="outlined"
                fullWidth
                size="small"
                error={!!formErrors.password}
                helperText={formErrors.password && formErrors.password.message}
                className="default-field"
                InputProps={
                  {
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    )
                  }
                }
              />
            )}
          />
          <Button
            variant="contained"
            size="large"
            type="submit"
            text={t(ResetPasswordMessages.resetPasswordButton)}
            loading={isLoading}
          />
        </form>
      </ResetPasswordFormContainer>
      <ResetPasswordDialogContainer className="dialog">
        <Dialog
          container={() => document.querySelector(".dialog") as HTMLElement}
          fullWidth
          open={dialogOpen}
        >
          <DialogTitle className="dialog__title"><img src={CheckGif} alt="Check" /></DialogTitle>
          <DialogContent className="dialog__content">
            <DialogContentText>
              {type === "confirm-account"
                ? Parser(t(ResetPasswordMessages.resetPasswordConfirmAccountMessage))
                : Parser(t(ResetPasswordMessages.resetPasswordForgotPasswordMessage))}
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog__actions">
            <Button
              variant="contained"
              size="large"
              type="submit"
              onClick={() => handleRedirectLoginPage()}
              text={t(ResetPasswordMessages.resetPasswordGoToLoginPageButton)}
            />
          </DialogActions>
        </Dialog>
      </ResetPasswordDialogContainer>
    </>
  );
};

export default ResetPasswordForm;
