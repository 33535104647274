import { GlobalMessages, TranslationGlobalMessages } from "@languages/interfaces/globalMessages";

export const messages: TranslationGlobalMessages = {
  [GlobalMessages.filtersText]: "Filtros",
  [GlobalMessages.activeText]: "Activo",
  [GlobalMessages.inactiveText]: "Inactivo",
  [GlobalMessages.columnActions]: "Actions",
  [GlobalMessages.buttonAdd]: "Añadir nuevo",
  [GlobalMessages.buttonRefresh]: "Actualizar",
  [GlobalMessages.entityUser]: "Usuario",
  [GlobalMessages.yesText]: "Sí",
  [GlobalMessages.noText]: "No",
  [GlobalMessages.resetPasswordText]: "Restabelecer la contraseña"
};
