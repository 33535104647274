import { DataTableMessages, TranslationDataTableMessages } from "../interfaces/dataTableMessages";

export const messages: TranslationDataTableMessages = {
  [DataTableMessages.dtEmptyTable]: "No se encontraron registros",
  [DataTableMessages.dtInfo]: "Mostrando _START_ a _END_ de _TOTAL_ registros",
  [DataTableMessages.dtInfoEmpty]: "Mostrando 0 a 0 de 0 registros",
  [DataTableMessages.dtInfoFiltered]: "(Filtrado de _MAX_ registros totales)",
  [DataTableMessages.dtLengthMenu]: "_MENU_ registros por página",
  [DataTableMessages.dtLoadingRecords]: "Cargando...",
  [DataTableMessages.dtProcessing]: "Procesando...",
  [DataTableMessages.dtZeroRecords]: "No se encontraron registros",
  [DataTableMessages.dtSearchPlaceholder]: "Buscar..."
};
