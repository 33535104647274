/* eslint-disable no-restricted-globals */

import axios from "axios";
import utils from "@helpers/utils";

const api = axios.create({
  baseURL: utils.getBackendBaseUrl()
});

export default api;
