import React from "react";
import packageJson from "../../../package.json";

import * as Styled from "./styles";

const Version: React.FC = () => (
  <Styled.VersionContainer className="container">
    <div>V.{packageJson.version}</div>
  </Styled.VersionContainer>
);

export default Version;
