/* eslint-disable no-restricted-globals */

import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import jwt from "jwt-decode";
import { AxiosResponse } from "axios";

import api from "@services/rest";
import { User } from "@interfaces/user.interface";
import { IResetPasswordProps } from "@pages/ResetPassword";
import { ECommandResult } from "@store/enums/commandResult.enum";

import useTranslation from "@languages/useTranslation";

interface SignInCredentials {
  email: string;
  password: string;
}

interface ConfirmNewPasswordCredentials {
  idUser: string;
  newPassword: string;
  confirmationToken: string;
  type: IResetPasswordProps["type"];
}

interface AuthState {
  user: User;
}

interface AuthContextData {
  user: User;
  validateToken: (token: string) => boolean;
  getTokenData: (token: string) => any;
  signIn(credentials: SignInCredentials): Promise<any>;
  signOut(): void;
  confirmNewPassword(credentials: ConfirmNewPasswordCredentials): Promise<AxiosResponse>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);
const AuthProvider: React.FC = ({ children }) => {

  const { i18n } = useTranslation();

  const [data, setData] = useState<AuthState>(() => {

    const token = localStorage.getItem("@Topcon:token");
    const user = localStorage.getItem("@Topcon:user");

    if (token && user) {
      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      api.defaults.headers.common["Accept-Language"] = i18n.language;

      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  /**
   * Log in and store data access
   * @param { email, password }
   */
  const signIn = useCallback(async ({ email, password }) => {

    const response = await api.post("/auth/login", { email, password });
    const responseData = response.data;

    if (responseData.status === ECommandResult.SUCCESS) {

      const user = responseData.result;

      localStorage.setItem("@Topcon:token", user.token);
      localStorage.setItem("@Topcon:user", JSON.stringify(user));
      api.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
      setData({ user });
    }

    return responseData;

  }, []);

  /**
   * Log out and clear store data access
   */
  const signOut = useCallback(() => {

    localStorage.removeItem("@Topcon:user");
    setData({} as AuthState);

  }, []);

  /**
   * Get token decrypted data
   * @param token
   */
  const getTokenData = useCallback((token: string) => jwt(token) as any, []);

  /**
   * Verify if token is valid
   * @param token
   */
  const validateToken = useCallback((token: string) => {

    if (!token) return false;

    try {
      const decoded = getTokenData(token);

      if (!decoded.exp) return false;

      return Date.now() < decoded.exp * 1000;
    } catch (e) {
      return false;
    }
  }, [getTokenData]);

  /**
   * Confirm new password action
   * For reset and confirm account
   * @param idUser User to change / confirm
   * @param password New password
   * @param confirmationToken Generated token to confirm account
   */
  const confirmNewPassword = useCallback(async ({ idUser, newPassword, confirmationToken, type }) => {

    const typeRequest = type === "confirm-account" ? "confirmaccount" : "changepassword";
    const queryParams = `?userId=${idUser}&${type === "confirm-account" ? `code=${confirmationToken}` : ""}`;

    return await api.post(`/auth/${typeRequest}${queryParams}`, { password: newPassword });

  }, []);

  // Return the context data
  const provider = useMemo(() => ({ user: data.user, signIn, signOut, getTokenData, validateToken, confirmNewPassword }), []);

  return (
    <AuthContext.Provider value={provider}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  return useContext(AuthContext);
}

export { AuthProvider, useAuth };
