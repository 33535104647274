import { YupMessages, TranslationYupMessages } from "../interfaces/yupMessages";

export const messages: TranslationYupMessages = {
  [YupMessages.mixedDefault]: "This field is invalid",
  [YupMessages.mixedRequired]: "This field is required",
  [YupMessages.mixedOneOf]: "This field must be one of the following values: {{values}}",
  [YupMessages.mixedNotOneOf]: "This field must not be one of the following values: {{values}}",
  [YupMessages.mixedNotType]: "This field is required",

  [YupMessages.stringLength]: "This field must be exactly {{length}} characters",
  [YupMessages.stringMin]: "This field must be at least {{min}} characters",
  [YupMessages.stringMax]: "This field must be at most {{max}} characters",
  [YupMessages.stringMatches]: "This field must match the following: \"{{regex}}\"",
  [YupMessages.stringEmail]: "This field must be a valid email",
  [YupMessages.stringUrl]: "This field must be a valid URL",
  [YupMessages.stringTrim]: "This field must be a trimmed string",
  [YupMessages.stringLowerCase]: "This field must be a lowercase string",
  [YupMessages.stringUpperCase]: "This field must be a upper case string",

  [YupMessages.numberMin]: "This field must be greater than or equal to {{min}}",
  [YupMessages.numberMax]: "This field must be less than or equal to {{max}}",
  [YupMessages.numberLessThan]: "This field must be less than {{less}}",
  [YupMessages.numberMoreThan]: "This field must be greater than {{more}}",
  [YupMessages.numberPositive]: "This field must be a positive number",
  [YupMessages.numberNegative]: "This field must be a negative number",
  [YupMessages.numberInteger]: "This field must be an integer",

  [YupMessages.dateMin]: "This date must be later than {{min}}",
  [YupMessages.dateMax]: "Field date be at earlier or equal to today",

  [YupMessages.objectNoUnknown]: "This field has unspecified keys: {{unknown}}",

  [YupMessages.arrayMin]: "This field must have at least {{min}} item(s)",
  [YupMessages.arrayMax]: "This field must have less than or equal to {{max}} items"
};
