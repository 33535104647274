import { FilterMessages, TranslationFilterMessages } from "@languages/interfaces";

export const messages: TranslationFilterMessages = {
  [FilterMessages.buttonAddFieldsFilter]: "Add filter",
  [FilterMessages.buttonRemoveAllFieldsFilter]: "Remove all",
  [FilterMessages.buttonSaveFieldFilter]: "Save",
  [FilterMessages.buttonApplyFilter]: "Apply filter",
  [FilterMessages.buttonSaveSelectionsAsNewFilter]: "Save selections as new filter",
  [FilterMessages.buttonSaveChangesToSelectedFilter]: "Save changes to selected filter",
  [FilterMessages.buttonSetFilterAsDefault]: "Set filter as default",
  [FilterMessages.buttonRemoveFilterDefault]: "Remove default filter",
  [FilterMessages.labelFieldProperty]: "Field",
  [FilterMessages.labelFieldOperator]: "Operator",
  [FilterMessages.labelFieldValue]: "Value",
  [FilterMessages.labelFilterOptions]: "Filter options",
  [FilterMessages.labelChooseFilterName]: "Choose filter name",
  [FilterMessages.labelFilterName]: "Filter name",
  [FilterMessages.labelSavedFilters]: "Saved filters",
  [FilterMessages.labelFilterDataOnScreen]: "Filter data on screen",
  [FilterMessages.operatorEqual]: "Equal",
  [FilterMessages.operatorNotEqual]: "Not equal",
  [FilterMessages.operatorGreater]: "Greater",
  [FilterMessages.operatorGreaterOrEqual]: "Greater or equal",
  [FilterMessages.operatorLess]: "Less",
  [FilterMessages.operatorLessOrEqual]: "Less or equal",
  [FilterMessages.operatorContains]: "Contains",
  [FilterMessages.operatorNotContains]: "Not contains",
  [FilterMessages.operatorStartsWith]: "Starts with",
  [FilterMessages.infoAppliedText]: "Filter applied to",
  [FilterMessages.infoCorrespondenceText]: "Correspondence of all these filters",
  [FilterMessages.infoNoFiltersApplied]: "No filters applied",
  [FilterMessages.infoNewFieldFilter]: "New field filter",
  [FilterMessages.infoRequiredField]: "All fields are mandatory",
  [FilterMessages.infoMaxFilterFields]: "Maximum number of filters reached"
};
