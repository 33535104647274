import styled from "styled-components";

const FormContainer = styled.form`
  overflow-y: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding-top: 5px;
  padding-bottom: 20px;

  > :last-child {
    margin-top: 20px;
  }
`;

export { FormContainer };
