import React from "react";
import { Navigate } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";

interface RouteProps<T = any> {
  component: React.ReactElement<T>;
  onlyAdmin?: boolean;
}

const PrivateRoute: React.FC<RouteProps> = ({
  component: Component,
  onlyAdmin = false
}) => {
  const { user } = useAuth();

  if (onlyAdmin && user) {
    return user.admin ? Component : <Navigate to="/" />;
  }

  return user ? Component : <Navigate to="/" />;
};

PrivateRoute.defaultProps = {
  onlyAdmin: false
};

export default PrivateRoute;