import { YupMessages, TranslationYupMessages } from "../interfaces/yupMessages";

export const messages: TranslationYupMessages = {
  [YupMessages.mixedDefault]: "Este campo no es válido",
  [YupMessages.mixedRequired]: "Este campo es obligatorio",
  [YupMessages.mixedOneOf]: "Este campo debe ser uno de los siguientes valores: {{values}}",
  [YupMessages.mixedNotOneOf]: "Este campo no debe ser uno de los siguientes valores: {{values}}",
  [YupMessages.mixedNotType]: "Este campo es obligatorio",

  [YupMessages.stringLength]: "Este campo debe ser exactamente {{length}} caracteres",
  [YupMessages.stringMin]: "Este campo debe ser al menos {{min}} caracteres",
  [YupMessages.stringMax]: "Este campo debe ser como máximo {{max}} caracteres",
  [YupMessages.stringMatches]: "Este campo debe coincidir lo siguiente: \"{{regex}}\"",
  [YupMessages.stringEmail]: "Este campo debe ser un correo electrónico válido.",
  [YupMessages.stringUrl]: "Este campo debe ser una URL válida.",
  [YupMessages.stringTrim]: "Este campo debe ser una cadena recortada.",
  [YupMessages.stringLowerCase]: "Este campo debe ser una cadena en minúscula.",
  [YupMessages.stringUpperCase]: "Este campo debe ser una cadena de mayúsculas.",

  [YupMessages.numberMin]: "Este campo debe ser mayor o igual a {{min}}",
  [YupMessages.numberMax]: "Este campo debe ser menor o igual a {{max}}",
  [YupMessages.numberLessThan]: "Este campo debe ser menor que {{less}}",
  [YupMessages.numberMoreThan]: "Este campo debe ser mayor que {{more}}",
  [YupMessages.numberPositive]: "Este campo debe ser un número positivo.",
  [YupMessages.numberNegative]: "Este campo debe ser un número negativo.",
  [YupMessages.numberInteger]: "Este campo debe ser un número entero.",

  [YupMessages.dateMin]: "Esta fecha debe ser posterior a {{min}}",
  [YupMessages.dateMax]: "Fecha de campo estar antes o igual a hoy",

  [YupMessages.objectNoUnknown]: "Este campo tiene llaves no especificadas: {{unknown}}",

  [YupMessages.arrayMin]: "Este campo debe tener al menos {{min}} elemento(s)",
  [YupMessages.arrayMax]: "Este campo debe tener menos o igual a {{max}} elementos"
};
