import { ForgotPasswordMessages, TranslationForgotPasswordMessages } from "../interfaces/forgotPasswordMessages";

export const messages: TranslationForgotPasswordMessages = {
  [ForgotPasswordMessages.forgotPasswordEmailLabel]: "Email",
  [ForgotPasswordMessages.forgotPasswordRequiredEmail]: "Email é obrigatório",
  [ForgotPasswordMessages.forgotPasswordInvalidEmail]: "Email inválido",
  [ForgotPasswordMessages.forgotPasswordButton]: "Enviar email",
  [ForgotPasswordMessages.forgotPasswordGoToLoginPageButton]: "Ir para a página de login",
  [ForgotPasswordMessages.forgotPasswordInfo]: "Digite seu endereço de e-mail e enviaremos <br> um link para"
  + " redefinir"
  + " sua senha.",
  [ForgotPasswordMessages.forgotPasswordMessage]: "Enviamos um e-mail com um link para redefinir sua senha."
};
