export enum ForgotPasswordMessages {
  forgotPasswordEmailLabel = "forgot.password.email.label",
  forgotPasswordRequiredEmail = "forgot.password.required.email",
  forgotPasswordInvalidEmail = "forgot.password.invalid.email",
  forgotPasswordButton = "forgot.password.button",
  forgotPasswordGoToLoginPageButton = "forgot.password.goToLoginPage.button",
  forgotPasswordInfo = "forgot.password.info",
  forgotPasswordMessage = "forgot.password.message"
}

export interface TranslationForgotPasswordMessages {
  [ForgotPasswordMessages.forgotPasswordEmailLabel]: string;
  [ForgotPasswordMessages.forgotPasswordRequiredEmail]: string;
  [ForgotPasswordMessages.forgotPasswordInvalidEmail]: string;
  [ForgotPasswordMessages.forgotPasswordButton]: string;
  [ForgotPasswordMessages.forgotPasswordGoToLoginPageButton]: string;
  [ForgotPasswordMessages.forgotPasswordInfo]: string;
  [ForgotPasswordMessages.forgotPasswordMessage]: string;
}
