import { FilterMessages, TranslationFilterMessages } from "@languages/interfaces";

export const messages: TranslationFilterMessages = {
  [FilterMessages.buttonAddFieldsFilter]: "Adicionar filtro",
  [FilterMessages.buttonRemoveAllFieldsFilter]: "Remover todos",
  [FilterMessages.buttonSaveFieldFilter]: "Salvar",
  [FilterMessages.buttonApplyFilter]: "Aplicar filtro",
  [FilterMessages.buttonSaveSelectionsAsNewFilter]: "Salvar seleções como novo filtro",
  [FilterMessages.buttonSaveChangesToSelectedFilter]: "Salvar alterações no filtro selecionado",
  [FilterMessages.buttonSetFilterAsDefault]: "Definir como filtro padrão",
  [FilterMessages.buttonRemoveFilterDefault]: "Remover filtro padrão",
  [FilterMessages.labelFieldProperty]: "Propriedade",
  [FilterMessages.labelFieldOperator]: "Operador",
  [FilterMessages.labelFieldValue]: "Valor",
  [FilterMessages.labelFilterOptions]: "Filtrar opções",
  [FilterMessages.labelChooseFilterName]: "Escolha o nome do filtro",
  [FilterMessages.labelFilterName]: "Nome do filtro",
  [FilterMessages.labelSavedFilters]: "Filtros salvos",
  [FilterMessages.labelFilterDataOnScreen]: "Filtrar dados em tela",
  [FilterMessages.operatorEqual]: "Igual a",
  [FilterMessages.operatorNotEqual]: "Diferente de",
  [FilterMessages.operatorGreater]: "Maior que",
  [FilterMessages.operatorGreaterOrEqual]: "Maior ou igual a",
  [FilterMessages.operatorLess]: "Menor que",
  [FilterMessages.operatorLessOrEqual]: "Menor ou igual a",
  [FilterMessages.operatorContains]: "Contém",
  [FilterMessages.operatorNotContains]: "Não contém",
  [FilterMessages.operatorStartsWith]: "Começa com",
  [FilterMessages.infoAppliedText]: "Filtro aplicado a",
  [FilterMessages.infoCorrespondenceText]: "Correspondência de todos estes filtros",
  [FilterMessages.infoNoFiltersApplied]: "Não há filtros aplicados",
  [FilterMessages.infoNewFieldFilter]: "Novo filtro de campo",
  [FilterMessages.infoRequiredField]: "Todos os campos são obrigatórios",
  [FilterMessages.infoMaxFilterFields]: "Número máximo de filtros atingido"
};
