import { createGlobalStyle } from "styled-components";

export const windowWidth = {
  uhd: "2560px",
  laptop: {
    large: "1440px",
    medium: "1024px"
  },
  tablet: "768px",
  mobile: {
    large: "425px",
    medium: "375px",
    small: "320px"
  }
};
export const fieldMinWidth = "330px";
const fieldMinHeight = "80px";

export default createGlobalStyle`

  #root {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  * {
    font-family: 'Roboto', sans-serif;
    font: 'Roboto';
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;

    .default-field {
      min-width: ${fieldMinWidth} !important;
      min-height: ${fieldMinHeight} !important;

      @media ( max-width: ${windowWidth.mobile.large} ) {
        min-width: 100% !important;
      }
    }

    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgb(120,120,120);
      border-radius: 10px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: rgb(150,150,150);
      border-radius: 10px;
    }
  }

  a {
    border: none;
    outline: none;
    text-decoration: none;
  }
`;
