import React from "react";

/** Assets */
import TopconLogo from "@assets/TopconLogo/IconAndName.svg";

/** Molecules */
import { NavbarIcons } from "@molecules/index";

/** Styled components */
import * as Styled from "./styles";

/** Component */
const Navbar: React.FC = () => (
  <Styled.Container>

    <Styled.ProductIcon src={TopconLogo} alt="Topcon Tech icon" />

    <NavbarIcons />

  </Styled.Container>
);

export default React.memo(Navbar);
