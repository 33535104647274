import { ResetPasswordMessages, TranslationResetPasswordMessages } from "../interfaces/resetPasswordMessages";

// eslint-disable-next-line import/prefer-default-export
export const messages: TranslationResetPasswordMessages = {
  [ResetPasswordMessages.resetPasswordLabel]: "Contraseña",
  [ResetPasswordMessages.resetPasswordRequired]: "La contraseña es requerida",
  [ResetPasswordMessages.resetPasswordButton]: "Confirmar nueva contraseña",
  [ResetPasswordMessages.resetPasswordGoToLoginPageButton]: "Ir a la página de inicio de sesión",
  [ResetPasswordMessages.resetPasswordFailed]: "No se pudo confirmar la nueva contraseña",
  [ResetPasswordMessages.resetPasswordConfirmAccountInfo]: "Por favor, confirme su cuenta para restablecer su contraseña",
  [ResetPasswordMessages.resetPasswordConfirmAccountMessage]: "¡Cuenta confirmada con éxito!<br><br>"
  + "Haga clic en el botón de abajo para iniciar sesión por primera vez!<br>"
  + "¡Bienvenido a Topcon Suite!",
  [ResetPasswordMessages.resetPasswordForgotPasswordInfo]: "Por favor, ingrese su nueva contraseña",
  [ResetPasswordMessages.resetPasswordForgotPasswordMessage]: "¡Contraseña restablecida con éxito!<br><br>"
  + "Haga clic en el botón de abajo para iniciar sesión!"
};
