import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100vh;
`;

const NavbarContainer = styled.div`
  width: 100%;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;

  > :first-child {
    min-width: 55px;
  }
`;

const Content = styled.div`
  flex-grow: 1;
  height: 100%;
  color: ${(props) => props.theme.colors.text};
  overflow: hidden;
`;

export { Container, NavbarContainer, ContentContainer, Content };
