import React from "react";

import Version from "@atoms/Version";
import LoginForm from "@molecules/LoginForm";

import { LoginContainer, LoginFormContainer } from "./styles";

const Login: React.FC = () => (
  <LoginContainer>
    <LoginFormContainer>
      <LoginForm />
    </LoginFormContainer>
    <Version />
  </LoginContainer>
);

export default Login;
