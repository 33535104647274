import React from "react";
import ForgotPasswordForm from "@molecules/ForgotPasswordForm";
import { ForgotPasswordContainer, ForgotPasswordFormContainer } from "./styles";

const ForgotPassword: React.FC = () => (
  <ForgotPasswordContainer>
    <ForgotPasswordFormContainer>
      <ForgotPasswordForm />
    </ForgotPasswordFormContainer>
  </ForgotPasswordContainer>
);

export default ForgotPassword;
