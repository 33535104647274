import React from "react";

import { Close } from "@libraries/mui/icons";
import { IconButton } from "@libraries/mui/components";

import * as Styled from "./styles";

interface IModalFormDialog {
  id?: string;
  className?: "without-padding" | string;
  title: string;
  subtitle1?: string;
  subtitle2?: string;
  open: boolean;
  fullWidth?: boolean;
  onClose?: () => void;
}

const ModalFormDialog: React.FC<IModalFormDialog> = ({
  id, className, title, subtitle1, subtitle2, open, fullWidth, onClose, children
}) => (
  <Styled.Container open={open} fullWidth={fullWidth} id={id} className={className}>
    <Styled.Header>
      <Styled.Title>
        {title}
        {subtitle1 && <Styled.Subtitle>{subtitle1}</Styled.Subtitle>}
        {subtitle2 && <Styled.Subtitle>{subtitle2}</Styled.Subtitle>}
      </Styled.Title>
      {onClose && (
        <IconButton onClick={onClose}>
          <Close fontSize="large" />
        </IconButton>
      )}
    </Styled.Header>
    {children}
  </Styled.Container>
);

ModalFormDialog.defaultProps = {
  id: "modalFormDialog",
  className: "",
  fullWidth: false,
  subtitle1: undefined,
  subtitle2: undefined,
  onClose: undefined
};

export default ModalFormDialog;
