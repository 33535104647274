export enum LoginMessages {
  loginEmailLabel = "login.email.label",
  loginPasswordLabel = "login.password.label",
  loginCopyright = "login.copyright",
  loginTopconDevelopment = "login.topcon.development",
  loginLoginButton = "login.login.button",
  loginRequiredEmail = "login.required.email",
  loginInvalidEmail = "login.invalid.email",
  loginPasswordRequired = "login.password.required",
  loginFailed = "login.failed"
}

export interface TranslationLoginMessages {
  [LoginMessages.loginCopyright]: string;
  [LoginMessages.loginEmailLabel]: string;
  [LoginMessages.loginPasswordLabel]: string;
  [LoginMessages.loginTopconDevelopment]: string;
  [LoginMessages.loginLoginButton]: string;
  [LoginMessages.loginRequiredEmail]: string;
  [LoginMessages.loginInvalidEmail]: string;
  [LoginMessages.loginPasswordRequired]: string;
  [LoginMessages.loginFailed]: string;
}
