export enum GlobalMessages {
  filtersText = "global.filtersText",
  activeText = "global.activeText",
  inactiveText = "global.inactiveText",
  columnActions = "global.columns.columnActions",
  buttonAdd = "global.buttons.buttonAdd",
  buttonRefresh = "global.buttons.buttonRefresh",
  entityUser = "global.entities.entityUser",
  yesText = "global.yesText",
  noText = "global.noText",
  resetPasswordText = "global.resetPasswordText"
}

export interface TranslationGlobalMessages {
  [GlobalMessages.filtersText]: string;
  [GlobalMessages.activeText]: string;
  [GlobalMessages.inactiveText]: string;
  [GlobalMessages.columnActions]: string;
  [GlobalMessages.buttonAdd]: string;
  [GlobalMessages.buttonRefresh]: string;
  [GlobalMessages.entityUser]: string;
  [GlobalMessages.yesText]: string;
  [GlobalMessages.noText]: string;
  [GlobalMessages.resetPasswordText]: string;
}
