import { GlobalMessages, TranslationGlobalMessages } from "@languages/interfaces/globalMessages";

export const messages: TranslationGlobalMessages = {
  [GlobalMessages.filtersText]: "Filtros",
  [GlobalMessages.activeText]: "Ativo",
  [GlobalMessages.inactiveText]: "Inativo",
  [GlobalMessages.columnActions]: "Ações",
  [GlobalMessages.buttonAdd]: "Adicionar novo",
  [GlobalMessages.buttonRefresh]: "Atualizar",
  [GlobalMessages.entityUser]: "Usuário",
  [GlobalMessages.yesText]: "Sim",
  [GlobalMessages.noText]: "Não",
  [GlobalMessages.resetPasswordText]: "Redefinir senha"
};
