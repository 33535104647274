import { FilterMessages, TranslationFilterMessages } from "@languages/interfaces";

export const messages: TranslationFilterMessages = {
  [FilterMessages.buttonAddFieldsFilter]: "Añadir filtro",
  [FilterMessages.buttonRemoveAllFieldsFilter]: "Eliminar todo",
  [FilterMessages.buttonSaveFieldFilter]: "Guardar",
  [FilterMessages.buttonApplyFilter]: "Aplicar filtro",
  [FilterMessages.buttonSaveSelectionsAsNewFilter]: "Guardar selección como nuevo filtro",
  [FilterMessages.buttonSaveChangesToSelectedFilter]: "Guardar cambios en el filtro seleccionado",
  [FilterMessages.buttonSetFilterAsDefault]: "Establecer como filtro por defecto",
  [FilterMessages.buttonRemoveFilterDefault]: "Eliminar el filtro predeterminado",
  [FilterMessages.labelFieldProperty]: "Campo",
  [FilterMessages.labelFieldOperator]: "Operadora",
  [FilterMessages.labelFieldValue]: "Valor",
  [FilterMessages.labelFilterOptions]: "filtrar opciones",
  [FilterMessages.labelChooseFilterName]: "Elige el nombre del filtro",
  [FilterMessages.labelFilterName]: "Nombre del filtro",
  [FilterMessages.labelSavedFilters]: "Filtros guardados",
  [FilterMessages.labelFilterDataOnScreen]: "Filtrar datos en pantalla",
  [FilterMessages.operatorEqual]: "Igual",
  [FilterMessages.operatorNotEqual]: "No es igual",
  [FilterMessages.operatorGreater]: "Mayor que",
  [FilterMessages.operatorGreaterOrEqual]: "Mayor o igual",
  [FilterMessages.operatorLess]: "Menos",
  [FilterMessages.operatorLessOrEqual]: "Menos o igual",
  [FilterMessages.operatorContains]: "Contiene",
  [FilterMessages.operatorNotContains]: "No contiene",
  [FilterMessages.operatorStartsWith]: "Comienza con",
  [FilterMessages.infoAppliedText]: "Filtro aplicado a",
  [FilterMessages.infoCorrespondenceText]: "Correspondencia de todos estos filtros",
  [FilterMessages.infoNoFiltersApplied]: "No se aplican filtros",
  [FilterMessages.infoNewFieldFilter]: "Nuevo filtro de campo",
  [FilterMessages.infoRequiredField]: "Todos los campos son obligatorios",
  [FilterMessages.infoMaxFilterFields]: "Número máximo de filtros alcanzado"
};
