import styled from "styled-components";

import { Menu } from "@libraries/mui/components";

export const Container = styled(Menu)`
  > * {
    .selected {
      background-color: ${(props) => props.theme.colors.secondary} !important;
      color: white !important;
    }
  }
`;