/* eslint-disable react/require-default-props */

import React from "react";
import { Route, Routes as ReactDOMRoutes } from "react-router-dom";

/** Pages */
import Login from "@pages/Login";
import ResetPassword from "@pages/ResetPassword";
import ForgotPassword from "@pages/ForgotPassword";
import MaintenanceUser from "@organisms/MaintenanceUser";
import PrivateRoute from "src/routes/PrivateRoute";

const Routes: React.FC = () => (
  <ReactDOMRoutes>
    <Route path="/" element={<Login />} />
    <Route path="/confirm-account" element={<ResetPassword type="confirm-account" />} />
    <Route path="/reset-password" element={<ResetPassword type="reset-password" />} />
    <Route path="/forgot-password" element={<ForgotPassword />} />
    <Route path="/maintenanceUser" element={<PrivateRoute component={<MaintenanceUser />} onlyAdmin />} />
  </ReactDOMRoutes>
);

export default Routes;