import styled from "styled-components";
import { ReactSVG } from "react-svg";

interface IModuleNameProps {
  moduleColor: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ModuleIcon = styled(ReactSVG)`
  width: 20px;
  height: 20px;
  margin-right: 10px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const ModuleName = styled.span<IModuleNameProps>`

  .module {
    color: ${({ moduleColor }) => moduleColor};
    font-weight: 900;
  }
`;

export { Container, ModuleIcon, ModuleName };
