import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import portugueseBrazilian from "./pt-BR";
import englishUs from "./en-US";
import spanish from "./es";

const resources = {
  en: { ...englishUs },
  pt: { ...portugueseBrazilian },
  es: { ...spanish }
};

function i18nInit() {
  i18n
    .use(initReactI18next)
    .init({
      resources,
      lng: navigator.language,
      interpolation: {
        escapeValue: false
      }
    });
}

export default i18nInit;
