import {
  LoginMessages,
  TranslationLoginMessages
} from "../interfaces/loginMessages";

// eslint-disable-next-line import/prefer-default-export
export const messages: TranslationLoginMessages = {
  [LoginMessages.loginCopyright]: "All rights reserved | {{year}}",
  [LoginMessages.loginEmailLabel]: "Email",
  [LoginMessages.loginPasswordLabel]: "Password",
  [LoginMessages.loginTopconDevelopment]: "Topcon is developed by the Telluria Group",
  [LoginMessages.loginLoginButton]: "Login",
  [LoginMessages.loginPasswordRequired]: "Password required",
  [LoginMessages.loginInvalidEmail]: "Invalid email",
  [LoginMessages.loginRequiredEmail]: "Email required",
  [LoginMessages.loginFailed]: "Failed to log in, check your data and try again"
};
