import { GlobalMessages, TranslationGlobalMessages } from "@languages/interfaces/globalMessages";

export const messages: TranslationGlobalMessages = {
  [GlobalMessages.filtersText]: "Filters",
  [GlobalMessages.activeText]: "Active",
  [GlobalMessages.inactiveText]: "Inactive",
  [GlobalMessages.columnActions]: "Actions",
  [GlobalMessages.buttonAdd]: "Add new",
  [GlobalMessages.buttonRefresh]: "Refresh",
  [GlobalMessages.entityUser]: "User",
  [GlobalMessages.yesText]: "Yes",
  [GlobalMessages.noText]: "No",
  [GlobalMessages.resetPasswordText]: "Reset Password"
};
