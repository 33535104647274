import React from "react";
import { ReactSVG } from "react-svg";

import LogoIcon from "@assets/TopconLogo/Icon.svg";
import LogoName from "@assets/TopconLogo/Name.svg";

import LogoContainer from "./styles";

const Logo: React.FC = () => (
  <LogoContainer className="container">
    <ReactSVG className="logoIconSVG" src={LogoIcon} />
    <ReactSVG className="logoNameSVG" src={LogoName} />
  </LogoContainer>
);

export default Logo;
