import React from "react";

import SyncLoader from "react-spinners/SyncLoader";

import useTheme from "@styles/useTheme";
import * as Styled from "./styles";

interface LoadingProps {
  loading: boolean;
}

const Loading: React.FC<LoadingProps> = ({ loading }) => {

  const { theme } = useTheme();

  if (!loading) return null;

  return (
    <Styled.Container>
      <SyncLoader className="loading-icon-area" size={10} color={theme.colors.tertiary} loading={loading} />
    </Styled.Container>
  );
};

export default Loading;
