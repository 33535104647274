import {
  LoginMessages,
  TranslationLoginMessages
} from "../interfaces/loginMessages";

// eslint-disable-next-line import/prefer-default-export
export const messages: TranslationLoginMessages = {
  [LoginMessages.loginCopyright]: "Reservados todos los derechos | {{year}}",
  [LoginMessages.loginEmailLabel]: "Correo electrónico",
  [LoginMessages.loginPasswordLabel]: "Clave",
  [LoginMessages.loginTopconDevelopment]: "Topcon es desarrollado por el Grupo Telluria",
  [LoginMessages.loginLoginButton]: "Acceso",
  [LoginMessages.loginPasswordRequired]: "Se requiere contraseña",
  [LoginMessages.loginInvalidEmail]: "Correo electrónico inválido",
  [LoginMessages.loginRequiredEmail]: "Correo electrónico (requerido",
  [LoginMessages.loginFailed]: "Error al iniciar sesión, verifique sus datos e intente nuevamente"
};
