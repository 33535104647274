import styled from "styled-components";

const VersionContainer = styled.div`
  font-size: 0.7rem;
  width: 100%;
  color: #8d8d8d;
  text-align: right;
  padding: 10px;
`;

export { VersionContainer };
