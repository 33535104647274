import { windowWidth } from "@styles/global";
import Login from "@assets/Backgrounds/Login.svg";
import LoginMobile from "@assets/Backgrounds/LoginMobile.svg";
import styled from "styled-components";

const ResetPasswordContainer = styled.div`
  background-image: url(${Login});
  background-size: cover;
  background-position: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: auto;

  @media (max-width: ${windowWidth.tablet}) {
    background-image: url(${LoginMobile});
  }
`;

const ResetPasswordFormContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;

  @media (max-width: ${windowWidth.mobile.large}) {
    width: 90%;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export {
  ResetPasswordContainer,
  ResetPasswordFormContainer
};
