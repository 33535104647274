import styled from "styled-components";

import { Dialog } from "@libraries/mui/components";

import { windowWidth } from "@styles/global";

const Container = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 10px !important;
    min-width: 400px !important;

    @media ( max-width: ${windowWidth.mobile.large} ) {
      min-width: 95% !important;
    } 
  }

  > * {
    .MuiButton-root {
      color: ${(props) => props.theme.colors.primary} !important;
    }
  }
`;

export { Container };
