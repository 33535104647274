export enum YupMessages {
  mixedDefault = "yup.mixed.default",
  mixedRequired = "yup.mixed.required",
  mixedOneOf = "yup.mixed.oneOf",
  mixedNotOneOf = "yup.mixed.notOneOf",
  mixedNotType = "yup.mixed.notType",

  stringLength = "yup.string.length",
  stringMin = "yup.string.min",
  stringMax = "yup.string.max",
  stringMatches = "yup.string.matches",
  stringEmail = "yup.string.email",
  stringUrl = "yup.string.url",
  stringTrim = "yup.string.trim",
  stringLowerCase = "yup.string.lowerCase",
  stringUpperCase = "yup.string.upperCase",

  numberMin = "yup.number.min",
  numberMax = "yup.number.max",
  numberLessThan = "yup.number.lessThan",
  numberMoreThan = "yup.number.moreThan",
  numberPositive = "yup.number.positive",
  numberNegative = "yup.number.negative",
  numberInteger = "yup.number.integer",

  dateMin = "yup.date.min",
  dateMax = "yup.date.max",

  objectNoUnknown = "yup.object.noUnknown",

  arrayMin = "yup.array.min",
  arrayMax = "yup.array.max",
}

export interface TranslationYupMessages {
  [YupMessages.mixedDefault]: string;
  [YupMessages.mixedRequired]: string;
  [YupMessages.mixedOneOf]: string;
  [YupMessages.mixedNotOneOf]: string;
  [YupMessages.mixedNotType]: string;

  [YupMessages.stringLength]: string;
  [YupMessages.stringMin]: string;
  [YupMessages.stringMax]: string;
  [YupMessages.stringMatches]: string;
  [YupMessages.stringEmail]: string;
  [YupMessages.stringUrl]: string;
  [YupMessages.stringTrim]: string;
  [YupMessages.stringLowerCase]: string;
  [YupMessages.stringUpperCase]: string;

  [YupMessages.numberMin]: string;
  [YupMessages.numberMax]: string;
  [YupMessages.numberLessThan]: string;
  [YupMessages.numberMoreThan]: string;
  [YupMessages.numberPositive]: string;
  [YupMessages.numberNegative]: string;
  [YupMessages.numberInteger]: string;

  [YupMessages.dateMin]: string;
  [YupMessages.dateMax]: string;

  [YupMessages.objectNoUnknown]: string;

  [YupMessages.arrayMin]: string;
  [YupMessages.arrayMax]: string;
}
