import { ForgotPasswordMessages, TranslationForgotPasswordMessages } from "../interfaces/forgotPasswordMessages";

export const messages: TranslationForgotPasswordMessages = {
  [ForgotPasswordMessages.forgotPasswordEmailLabel]: "Email",
  [ForgotPasswordMessages.forgotPasswordRequiredEmail]: "Email is required",
  [ForgotPasswordMessages.forgotPasswordInvalidEmail]: "Invalid email",
  [ForgotPasswordMessages.forgotPasswordButton]: "Send email",
  [ForgotPasswordMessages.forgotPasswordGoToLoginPageButton]: "Go to login page",
  [ForgotPasswordMessages.forgotPasswordInfo]: "Enter your email address and we will send <br> you a link to reset"
  + " your password.",
  [ForgotPasswordMessages.forgotPasswordMessage]: "We have sent you an email with a link to reset your password."
};
